<template>
  <div>
    <v-data-table
      :headers="headersDetail"
      :items="testScenarioRunDetail"
      :sort-by="['Order']"
      :sort-desc="[false]"
      disable-pagination
      hide-default-footer
      class="greenHeader text-pre-wrap secondary"
      :loading="isFindTestScenarioRunDetailPending"
      loading-text="Loading... Please wait"
    >
      <template v-slot:[`item.RunTest`]="{ item }">
        <v-icon
          v-if="item.OpenBug"
          :color="item.BlockingBug ? '#B20000FF' : '#FFC698'"
          @click="clickRun(item)"
        >
          mdi-bug-play
        </v-icon>
        <v-icon
          v-if="item.AllDone && !item.OpenBug"
          color="#49BD91"
          @click="clickRun(item)"
        >
          mdi-play-circle
        </v-icon>
        <v-icon
          v-if="!item.CanStart && !item.AllDone && !item.OpenBug"
          color="#CBCBCB"
        >
          mdi-play-circle
        </v-icon>
        <v-icon
          v-if="item.CanStart && !item.AllDone && !item.OpenBug"
          color="primary"
          @click="clickRun(item)"
        >
          mdi-play-circle
        </v-icon>

        <span v-if="false"> {{ item }}</span>
      </template>
      <template v-slot:[`item.Progress`]="{ item }">
        <v-progress-linear
          :value="(item.totalStepsDone / item.totalSteps) * 100"
          height="20"
          dark
          :class="
            applyDark(
              item.totalSteps == item.totalStepsDone
                ? 'green'
                : item.BlockingBug
                ? '#B20000FF'
                : item.OpenBug
                ? '#FFC698'
                : 'primary'
            )
              ? 'white--text'
              : 'black--text'
          "
          :color="
            item.totalSteps == item.totalStepsDone
              ? 'green'
              : item.BlockingBug
              ? '#B20000FF'
              : item.OpenBug
              ? '#FFC698'
              : 'primary'
          "
        >
          <strong>{{ item.totalStepsDone }} / {{ item.totalSteps }}</strong>
        </v-progress-linear>
      </template>

      <template
        v-if="$can('update', 'test-scenario-run')"
        v-slot:[`item.DueDate`]="props"
      >
        <v-edit-dialog
          :return-value.sync="props.item.DueDate"
          @save="props.item.save()"
          large
        >
          <v-icon
            :color="
              props.item.DueDate
                ? new Date(props.item.DueDate).toDateString() ===
                    new Date().toDateString() && !props.item.AllDone
                  ? 'green'
                  : new Date(props.item.DueDate) < new Date() &&
                    !props.item.AllDone
                  ? 'red'
                  : null
                : null
            "
            :disabled="props.item.AllDone"
            class="mr-2"
            >mdi-calendar</v-icon
          >

          <span
            v-if="
              new Date(props.item.DueDate).toISOString().substring(0, 10) ===
                new Date().toISOString().substring(0, 10) && !props.item.AllDone
            "
            class="green--text"
          >
            {{
              props.item.DueDate
                ? new Date(props.item.DueDate).toLocaleDateString()
                : ''
            }}
          </span>
          <span
            v-if="
              new Date(props.item.DueDate).toISOString().substring(0, 10) <
                new Date().toISOString().substring(0, 10) && !props.item.AllDone
            "
            class="red--text"
          >
            {{
              props.item.DueDate
                ? new Date(props.item.DueDate).toLocaleDateString()
                : ''
            }}
          </span>
          <span
            v-if="
              new Date(props.item.DueDate).toISOString().substring(0, 10) >
                new Date().toISOString().substring(0, 10) && !props.item.AllDone
            "
          >
            {{
              props.item.DueDate
                ? new Date(props.item.DueDate).toLocaleDateString()
                : ''
            }}
          </span>

          <template v-slot:input>
            <v-date-picker
              v-model="props.item.DueDate"
              no-title
            ></v-date-picker>
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:[`item.Assigned`]="{ item, index }">
        <v-menu>
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                  <v-progress-circular
                    v-if="saving[index]"
                    indeterminate
                    :size="15"
                    :width="1"
                  ></v-progress-circular>
                  <v-icon v-if="!item.AssignedUser && !saving[index]"
                    >mdi-account-plus</v-icon
                  >
                  <v-avatar
                    v-if="item.AssignedUser && !saving[index]"
                    :class="
                      applyDark(item.AssignedUser.color)
                        ? 'white--text caption'
                        : 'black--text caption'
                    "
                    text--color="white"
                    :color="item.AssignedUser.color"
                    size="25"
                    ><img
                      v-if="item.AssignedUser?.gravatarUrl"
                      :src="item.AssignedUser?.gravatarUrl"
                      :alt="item.AssignedUser?.fullname"
                    />
                    <span v-else>{{
                      item.AssignedUser.fullname
                        .match(/(\b\S)?/g)
                        .join('')
                        .match(/(^\S|\S$)?/g)
                        .join('')
                        .toUpperCase()
                    }}</span></v-avatar
                  >
                </v-btn>
              </template>
              <span>{{
                item.AssignedUser
                  ? item.AssignedUser.fullname
                  : 'No User Assigned'
              }}</span>
            </v-tooltip>
          </template>
          <v-list dense style="max-height: 500px" class="overflow-y-auto">
            <v-list-item
              v-for="(user, index2) in userList"
              :key="user.id"
              :value="index2"
              @click="assignUser(index, item, user.id)"
            >
              <v-list-item-title
                ><v-avatar
                  :class="
                    applyDark(user.color)
                      ? 'white--text caption mr-2'
                      : 'black--text caption mr-2'
                  "
                  text--color="white"
                  :color="user.color"
                  size="25"
                  ><img
                    v-if="user.gravatarUrl"
                    :src="user.gravatarUrl"
                    :alt="user.fullname"
                  />
                  <span v-else>{{
                    user.fullname
                      .match(/(\b\S)?/g)
                      .join('')
                      .match(/(^\S|\S$)?/g)
                      .join('')
                      .toUpperCase()
                  }}</span></v-avatar
                >{{ user.fullname }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- name.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase() -->
      </template></v-data-table
    >
    <TestScenarioRun
      v-if="showDialog"
      :testScenarioRun="testScenarioRun"
      :testScenarioRunDetail="currentItem"
      @cancel="showDialog = false"
    />
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import { applyDark } from '@/utils/Utilities';
import { handleErrorResponse } from '@/utils/MessageHandler';

import TestScenarioRun from '@/components/TestScenarios/TestScenarioRun/TestScenarioRun.vue';

export default {
  props: {
    testScenarioRun: { type: Object, required: true },
    userList: { type: Array, required: true },
  },
  mixins: [
    makeFindMixin({
      service: 'test-scenario-run-detail',
      watch: 'testScenarioRun.id',
    }),
  ],
  components: { TestScenarioRun },
  data() {
    return {
      saving: [],
      showDialog: false,
      headersDetail: [
        {
          text: 'Test Order',
          value: 'Order',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Process',
          value: 'process.ProcessName',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Name',
          value: 'Name',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Department',
          value: 'department.Name',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Due Date',
          value: 'DueDate',
          align: 'start',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Assigned',
          value: 'Assigned',
          align: 'center',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Progress',
          value: 'Progress',
          align: 'center',
          sortable: false,
          groupable: false,
          width: 100,
        },
        {
          text: 'Run Test',
          value: 'RunTest',
          align: 'center',
          sortable: false,
          groupable: false,
        },
      ],
    };
  },
  computed: {
    testScenarioRunDetailParams() {
      return { query: { TestScenarioRunId: this.testScenarioRun.id } };
    },
  },
  methods: {
    applyDark,
    clickRun(item) {
      this.showDialog = true;
      this.currentItem = item;
    },
    async assignUser(index, item, id) {
      this.saving[index] = true;
      const { TestScenarioRun, TestScenarioRunDetail } = this.$FeathersVuex.api;

      try {
        item.AssignedUserId = id;
        await item.save();
      } catch (error) {
        handleErrorResponse(error);
      }
      this.saving[index] = false;
      await TestScenarioRun.find({ query: { id: this.testScenarioRun.id } });
      await TestScenarioRunDetail.find({ query: { id: item.id } });
    },
  },
};
</script>

<style lang="scss" scoped></style>
