var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isPending)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":15,"width":1}}):_vm._e(),(!_vm.isPending && _vm.testScenarioOutput.length > 0)?_c('div',[_c('h3',[_vm._v("Output Summary")]),_c('ul',_vm._l((_vm.testScenarioOutput),function(output){return _c('li',{key:output.id},[_c('b',[_vm._v(_vm._s(output.Name))]),_vm._v(": "),(
            _vm.inputStructure.filter(
              (line) =>
                line.inputId == output.id ||
                line.steps.some((s) => s.inputId == output.id)
            )?.length > 0
          )?_c('div',_vm._l((_vm.inputStructure.filter(
              (line) =>
                line.inputId == output.id ||
                line.steps.some((s) => s.inputId == output.id)
            )),function(line){return _c('ul',{key:line.id},[_c('li',[_vm._v(" "+_vm._s(line.Number)+". "+_vm._s(line.Name)+" "),_c('ul',_vm._l((line.steps.filter(
                    (f) => f.inputId == output.id
                  )),function(step){return _c('li',{key:step.id},[_vm._v(" "+_vm._s(step.Number)+". "+_vm._s(step.Name)+" ")])}),0)])])}),0):_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v(" mdi-cancel ")])],1)}),0)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }